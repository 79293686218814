import axios from "axios";
import { API, lawyerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getProfile() {
    try {
        const response = await axios.get(`${lawyerAPI}/lawyer-profile`);

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Get Profile`);
        throw error;
    }
}

async function updateProfile(payload) {
    try {
        const response = await axios.put(
            `${lawyerAPI}/lawyer-profile`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Update Profile`);
        throw error;
    }
}

async function updatePassword(payload) {
    try {
        const response = await axios.put(
            `${lawyerAPI}/lawyer-profile/password`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Update Password`);
        throw error;
    }
}

async function forgetPassword(email) {
    try {
        const response = await axios.post(`${API}/reset-password`, {
            type: "lawyer",
            email: email
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Forget Password`);
        throw error;
    }
}

export default {
    getProfile,
    updateProfile,
    updatePassword,
    forgetPassword
};
