import { convertNullIfEmpty } from "@/utils/null-check";

export class ProfileModel {
    static putProfilePayload(e) {
        return {
            name: e.name || "",
            avatar: e.avatar || [],
            mobileISOCode: convertNullIfEmpty(e.phoneNo.mobileISOCode) || "",
            mobileCountryCode:
                convertNullIfEmpty(e.phoneNo.mobileCountryCode) || "",
            mobileNumber: convertNullIfEmpty(e.phoneNo.mobileNumber) || "",

            lawyerFirmName: e.lawyerFirmName || "",
            lawyerBranchName: e.lawyerBranchName || "",
            lawyerBranchAddress1: e.lawyerBranchAddress1 || "",
            lawyerBranchAddress2: e.lawyerBranchAddress2 || "",
            lawyerAreaId: e.lawyerAreaId || "",
            lawyerCityId: e.lawyerCityId || "",
            lawyerStateId: e.lawyerStateId || "",
            lawyerContactNumber: e.lawyerContactNumber || ""
        };
    }
    static getToEdit(e) {
        return {
            email: e.user?.email,

            name: convertNullIfEmpty(e.name) || "",
            avatar: e.avatar || [],
            mobileISOCode: e.user.mobileISOCode || "",
            mobileCountryCode: e.user.mobileCountryCode || "",
            mobileNumber: e.user.mobileNumber || "",

            lawyerFirmName: convertNullIfEmpty(e.lawyerFirmName) || "",
            lawyerBranchName: convertNullIfEmpty(e.lawyerBranchName) || "",
            lawyerBranchAddress1:
                convertNullIfEmpty(e.lawyerBranchAddress1) || "",
            lawyerBranchAddress2:
                convertNullIfEmpty(e.lawyerBranchAddress2) || "",

            area: e.lawyerArea || "",
            city: e.lawyerCity || "",
            state: e.lawyerState || "",

            lawyerAreaId: e.lawyerArea?.id || "",
            lawyerCityId: e.lawyerCity?.id || "",
            lawyerStateId: e.lawyerState?.id || "",
            lawyerContactNumber: e.lawyerContactNumber || ""
        };
    }
}
