<template>
  <div class="manage-profile">
    <div class="container fluid">
      <div class="card p-1 sm-p-3">
        <h3 class="pt-2 pb-5">Profile</h3>

        <display-profile
          v-if="!isEditMode && lawyer"
          :lawyer="lawyer"
          @edit="isEditMode = true"
        ></display-profile>

        <edit-profile
          v-if="isEditMode && lawyer"
          :lawyer="getToEdit(lawyer)"
          @cancel="isEditMode = false"
          @updateProfile="updateProfile"
          @updatePassword="updatePassword"
        ></edit-profile>
      </div>
    </div>
  </div>
</template>

<script>
import { profile as profileAPI } from "@/api";
import { ProfileModel } from "@/models";
import useUserSubmitHandler from "@/modules/User/composables/useUserSubmitHandler";

export default {
  setup(props, context) {
    const submitHandlerOptions = {
      role: "lawyer"
    };
    const { handleSubmitError } = useUserSubmitHandler(
      context,
      submitHandlerOptions
    );

    return {
      handleSubmitError
    };
  },
  components: {
    DisplayProfile: () => import("@/components/ManageProfile/DisplayProfile"),
    EditProfile: () => import("@/components/ManageProfile/EditProfile")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      isEditMode: false,
      lawyer: null,
      lawyerToEdit: null,

      getToEdit: ProfileModel.getToEdit
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getProfile();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get profile data. Please try again later."
        });
      }
    },
    async getProfile() {
      try {
        let data = await profileAPI.getProfile();
        this.lawyer = this._.cloneDeep(data);
      } catch (error) {
        throw error;
      }
    },
    async updateProfile(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await profileAPI.updateProfile(payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Profile has been updated successfully."
        });

        this.isEditMode = false;

        await this.getProfile();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.handleSubmitError(error);
      }
    },
    async updatePassword(payload, updateSuccess) {
      try {
        this.$store.commit("setIsLoading", true);
        await profileAPI.updatePassword(payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Password has been updated successfully."
        });

        updateSuccess(true);
        await this.getProfile();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        console.log(error);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update password. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-profile {
}
</style>
