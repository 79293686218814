import axios from "axios";
import { lawyerAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";

async function getClients({
    queries = {},
    page = 1,
    perPage = 15,
    include = ""
}) {
    let defaultInclude =
        "appUser,projectPurchaseLawyer,projectPurchaseReservation,projectUnit,agent";

    try {
        const response = await axios.get(
            `${lawyerAPI}/project-purchase-lawyers`,
            {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage,
                    include: `${defaultInclude}${include}`
                }
            }
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Get Purchase Clients`);
        throw error;
    }
}

async function getClient(id) {
    let include = [
        "projectPurchaseLawyer",
        "projectPurchaseReservation",
        "projectPurchaseBooking",
        "projectPurchaseMortgage.mortgageMethods.bankLoans",
        "projectUnit",
        "agent"
    ];
    try {
        const response = await axios.get(
            `${lawyerAPI}/project-purchase-lawyers/${id}?include=${include.join(
                ","
            )}`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Get Purchase Client (id:${id})`);
        throw error;
    }
}

async function setSPADate(id, payload) {
    try {
        const response = await axios.put(
            `${lawyerAPI}/project-purchase-lawyers/${id}/select-sign-spa-date`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Set SPADate (id: ${id})`);
        throw error;
    }
}
async function setLADate(id, payload) {
    try {
        const response = await axios.put(
            `${lawyerAPI}/project-purchase-lawyers/${id}/select-sign-la-date`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Set LADate (id: ${id})`);
        throw error;
    }
}
async function signSPA(id) {
    try {
        const response = await axios.put(
            `${lawyerAPI}/project-purchase-lawyers/${id}/sign-spa`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Sign SPA (id: ${id})`);
        throw error;
    }
}
async function signLA(id) {
    try {
        const response = await axios.put(
            `${lawyerAPI}/project-purchase-lawyers/${id}/sign-la`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Sign LA (id: ${id})`);
        throw error;
    }
}
async function payStampSPA(id, payload) {
    try {
        const response = await axios.put(
            `${lawyerAPI}/project-purchase-lawyers/${id}/spa-pay-stamp-duty`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Pay Stamp SPA (id: ${id})`);
        throw error;
    }
}
async function payStampLA(id, payload) {
    try {
        const response = await axios.put(
            `${lawyerAPI}/project-purchase-lawyers/${id}/la-pay-stamp-duty`,
            payload
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Pay Stamp LA (id: ${id})`);
        throw error;
    }
}

async function markWithLA(id) {
    try {
        const response = await axios.put(
            `${lawyerAPI}/project-purchase-lawyers/${id}/la-confirmed`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Mark sale as without LA  (id: ${id})`);
        throw error;
    }
}

async function markWithoutLA(id) {
    try {
        const response = await axios.put(
            `${lawyerAPI}/project-purchase-lawyers/${id}/without-la`
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Lawyer CP] Mark sale as with LA (id: ${id})`);
        throw error;
    }
}

export default {
    getClients,
    getClient,
    setSPADate,
    setLADate,
    signSPA,
    signLA,
    payStampSPA,
    payStampLA,
    markWithLA,
    markWithoutLA
};
